<template>

<v-layout class="mt-5 pa-0">

  <v-flex justify-center>

  <v-data-table 
    :headers="headers"
    :items="ArrayEjecutivos"
    :search="search"
    sort-by="calories"
    class="elevation-1 mx-1 mt-3"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Ejecutivos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

         <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
         
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mx-1" dark v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>

            <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
            </v-btn>

          </template>
          <v-card>

          <v-toolbar color="red">
            <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialog=false">close</v-icon>
          </v-toolbar>


            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field  v-model="editedItem.nombre_ejecutivo"
                    prepend-icon="face"
                     label="Nombre ejecutivo"></v-text-field>
                  </v-col>
                  
                  
                 <v-col cols="12" sm="4" md="4">
                     <v-select
                     prepend-icon="privacy_tip"
                              v-model="editedItem.rol"
                              :items="rolesArray"
                              label="rol"
                          ></v-select>

                  </v-col>

                   <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.usr" 
                    prepend-icon="person"    label="usr"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.pwd"
                    prepend-icon="lock" label="pwd"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="12">
                    <v-text-field v-model="editedItem.correo"
                     prepend-icon="email" label="Correo"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.telefono"
                    prepend-icon="phone" 
                    label="Telefono"></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="blue darken-1" text @click="close">Cancelar</v-btn>  
              <v-btn color="success darken-1" @click="save">Guardar</v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas deshabilitar ejecutivo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon  small  class="mr-2" @click="editItem(item)">
        edit
      </v-icon>
      <v-icon small color="red" @click="deleteItem(item)">
      delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>


   
  </v-data-table>

  </v-flex>

</v-layout>
</template>

<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";


  export default {
    data: () => ({
      search:'',
       rolesArray:[
           {text:'ATC', value:'ATC'},
           {text:'ADMIN', value:'ADMIN'},
           {text:'OPERATOR', value:'OPERATOR'},
           {text:'CONTABILIDAD', value:'CONTA'},
           {text:'PROGRAMACION', value:'PROGRAMACION'},
           {text:'TECNICO', value:'TECNICO'}
         ],
      ArrayEjecutivos:[],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Nombre Ejecutivo', value: 'nombre_ejecutivo' },
        { text: 'rol', value: 'rol' },
        { text: 'usr', value: 'usr' },
        { text: 'correo', value: 'correo' },
        { text: 'telefono', value: 'telefono'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
       nombre_ejecutivo: '',
        rol: '',
        usr: '',
        pwd: '',
        correo:'',
        telefono:'',
        activo: 1,
        creado:new Date()
        },
      defaultItem: {
        nombre_ejecutivo: '',
        rol: '',
        usr: '',
        pwd: '',
        correo:'',
        telefono:'',
        activo: 1,
        creado:new Date()
      },
    }),

    computed: {
       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo ejecutivo' : 'Edicion ejecutivo'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.listar();
    },

    methods: {


         ...mapMutations(['mostrarLoading','ocultarLoading']),


    listar () {

        let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('ejecutivos',
         config
          ).then(function(response){
             console.log(response);
             me.ArrayEjecutivos=response.data;
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

        
      },


    

      editItem (item) {
        this.editedIndex = this.ArrayEjecutivos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayEjecutivos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.ArrayEjecutivos.splice(this.editedIndex, 1)
        
         let config={headers:{token:this.token}};
            
                    axios.put(`ejecutivo/${this.editedItem._id}`,
                              { '_id':this.editedItem._id,'activo':0},
                          config
                        ).then(response=>{
                      
                        console.log(response);
                     

                        this.closeDelete();
                        this.listar();

                  }).catch(e=>{
                        console.log(e)
                  });
        
      
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {


        //  Object.assign(this.ArrayEjecutivos[this.editedIndex], this.editedItem)

          //edicion de ejecutivo

          
           //actualizar datos de item
                    let config={headers:{token:this.token}};
                      this.mostrarLoading({titulo:'Accediendo a datos'});
             
                    let id=this.editedItem._id;
                    let me=this;

                    axios.put(`ejecutivo/${id}`,
                      { 
                      '_id':this.editedItem._id,
                      'nombre_ejecutivo':this.editedItem.nombre_ejecutivo,
                      'rol':this.editedItem.rol,
                      'usr':this.editedItem.usr,
                      'pwd':this.editedItem.pwd,
                      'correo':this.editedItem.correo,
                      'telefono':this.editedItem.telefono,
                      'activo':this.editedItem.activo
                       },
                      config

                        ).then(response=>{
                      
                        console.log(response);
                         me.ocultarLoading();
                     
                        this.listar();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });



        } else {
        //  this.ArrayEjecutivos.push(this.editedItem)


        if((this.editedItem.nombre_ejecutivo!='')&&(this.editedItem.rol!='')&&(this.editedItem.usr!='')
        &&(this.editedItem.pwd!='')&&(this.editedItem.correo!='')&&(this.editedItem.telefono!='')){

                    //nuevo ejecutivo

                  let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
                     let me=this;

                axios.post('nuevo-ejecutivo',
                          { 
                            'nombre_ejecutivo':this.editedItem.nombre_ejecutivo,
                            'rol':this.editedItem.rol,
                            'usr':this.editedItem.usr,
                            'pwd':this.editedItem.pwd,
                            'correo':this.editedItem.correo,
                            'telefono':this.editedItem.telefono,
                            'activo':this.editedItem.activo
                            },
                      config
                ).then(response=>{
                
                  this.close();
                  console.log(response);
                   me.ocultarLoading();
                  this.listar();

                }).catch(e=>{
                      console.log(e)
                    
                });


        }else{

          alert('faltan campos');

        }




        }
      
      },
    },
  }
</script>